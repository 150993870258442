
import { EnumCols, PageBase } from '@/core/models/shared';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesaChave extends PageBase { 
    service = new EmpreendimentoService();
    item: Model[]=[];
    overlay: boolean = false;
    filter:any ={}
  
    headers = {
        tamanhos:[EnumCols.g, EnumCols.g, EnumCols.m, EnumCols.m, EnumCols.m, EnumCols.p, EnumCols.g]
    }

    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;   

        this.service.ListarUnidades(this.filter.empreendimentoId, this.filter.situacaoId, this.filter.empresaId)
            .then(
                res=>{
                    this.item = res.data;
                }, 
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                } 
            ).finally(() =>{this.overlay = false});
        } 
        
}
class UnidadeModel{
    public id:number = 0;
    public nome:string ='';
    public situacao:string ='';
    public grupoNome:string ='';
    public empreendimento = new EmpreendimentoModel();
    public empresa = new EmpresaModel();
}

class EmpreendimentoModel{
    public id:number = 0;
    public empreendimentoNome:string =''
}

class EmpresaModel{
    public id:number = 0;
    public empresaNome:string =''
}

class ContratoModel{
    public id:number =0;
    public unidade:number = 0;
    public numero:number = 0;
    public clientes =[];
}

class Model {
    public unidade = new UnidadeModel();
    public contrato = new ContratoModel();
}
